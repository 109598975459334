<template>
  <div>
    <v-dialog
      persistent
      content-class="video-player-dialog"
      v-model="videoPlayerDialog"
    >
      <div class="dialog-container">
        
        <Player
          :autoplay="true"
          hideOnMouseLeave
          :activeDuration="1500"
          :style="{
            '--vm-slider-value-color': '#A6CC39',
            '--vm-time-color': '#FFFFFF',
          }"
          playsinline
          ref="player"
          :duration="duration"
          :currentTime="currentTime"
          :buffering="buffering"
          @vmFullscreenChange="onFullscreenChange"
          @vmPlayingChange="onPlayingChange"
          @vmCurrentTimeChange="onTimeUpdate"
          @vmPlaybackEnded="onPlaybackEnded"
          @vmPlaybackStarted="onStartNewPlayback"
          @vmSeeked="onSeekedVideo(true)"
          @vmDurationChange="onDurationChange"
          @vmCurrentSrcChange="onCurrentSrcChange"
          @vmPlaybackReady="onReadytoPlay"
          @vmBufferingChange="onVideoBuffering"
        >
          <div class="back-icon-container">
            <v-btn
              dark
              id="ButtonAnimationBack"
              style="cursor: pointer; min-width: 29px"
              @click.stop="showExitVideoPlayerDialog()"
            >
              <v-img
                src="@/assets/images/player-back-icon.svg"
                width="27"
                height="27"
                style="cursor: pointer"
                class="mx-auto"
              ></v-img>
            </v-btn>
          </div>
          <div class="watermark-overlays">
          <p class="mb-0">
            {{ user_ID }} / {{ user_Name }} / {{ formattedDateTime }}
          </p>
        </div>
          <div class="heart-icon-container" v-if="courseID == -1">
            <!-- hide favorite from course module -->
            <v-btn
              v-if="isFavourite == true"
              @click.stop="likeVideo()"
              dark
              id="ButtonAnimationHeart"
              class="play-name"
              style="cursor: pointer"
            >
              <v-img
                src="@/assets/images/player-heart-icon.svg"
                width="27"
                height="25"
                style="cursor: pointer"
                class="mx-auto imageShadow"
              ></v-img>
            </v-btn>

            <v-btn
              v-else
              dark
              id="ButtonAnimationHeart"
              class="play-name"
              style="cursor: pointer"
              @click.stop="likeVideo()"
            >
              <v-img
                src="@/assets/images/player-heart-outline-icon.svg"
                width="27"
                height="25"
                style="cursor: pointer"
                class="mx-auto"
              ></v-img>
            </v-btn>
          </div>

          <Video>
            <source :data-src="videoFile" type="video/mp4" />
          </Video>

          <Ui>
            <Scrim />
            <div
              v-if="loadVideo"
              style="
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <v-progress-circular
                v-if="loadVideo"
                indeterminate
                :size="70"
                class="loading-circle"
                color="#A6CC39"
              ></v-progress-circular>
            </div>
            <Controls justify="center">
              <div class="controls">
                <ControlGroup v-if="viewerFlag" class="controls-padding">
                  <div class="progress-bar" @click="seekVideo">
                    <div class="progress-bar__background"></div>
                    <div
                      class="progress-bar__fill"
                      :style="{ width: progressPercentage }"
                    ></div>
                    <div>
                      <div
                        class="progress-bar__handle"
                        :style="{ left: progressPercentage }"
                      ></div>
                    </div>
                  </div>
                </ControlGroup>
                <ControlGroup v-else class="controls-padding">
                  <div class="progress-bar" @click="handleClick">
                    <div class="progress-bar__background"></div>
                    <div
                      class="progress-bar__fill"
                      :style="{ width: progressPercentage }"
                    ></div>
                    <div>
                      <div
                        class="progress-bar__handles"
                        :style="{ left: progressPercentage }"
                      ></div>
                    </div>
                  </div>
                </ControlGroup>
                <ControlGroup
                  class="mt-1"
                  style="padding-left: 19px; padding-right: 19px"
                >
                  <CurrentTime alwaysShowHours />
                  <ControlSpacer />
                  <EndTime alwaysShowHours />
                </ControlGroup>

                <ControlGroup
                  class="mt-0"
                  style="
                    padding-left: 10px;
                    padding-right: 12px;
                    padding-bottom: 0px;
                  "
                >
                  <v-btn
                    dark
                    id="ButtonAnimationControls"
                    class="pause-name"
                    style="cursor: pointer"
                    @click.stop="restartVideo()"
                  >
                    <v-img
                      src="@/assets/images/player-pause-icon.svg"
                      width="19"
                      height="18"
                      class="mx-auto"
                    ></v-img>
                  </v-btn>
                  <ControlSpacer />
                  <v-btn
                    v-if="PlayBtn"
                    dark
                    id="ButtonAnimationControls"
                    class="play-name"
                    style="cursor: pointer"
                    @click="playVideo(playing)"
                  >
                    <v-img
                      src="@/assets/images/player-play-icon.svg"
                      width="20"
                      height="19"
                      class="mx-auto"
                    ></v-img>
                  </v-btn>
                  <v-btn
                    v-else
                    dark
                    id="ButtonAnimationControls"
                    class="play-name"
                    style="cursor: pointer"
                    @click="playVideo(playing)"
                  >
                    <v-icon style="font-size: 27px" color="white"
                      >mdi-pause</v-icon
                    >
                  </v-btn>
                  <ControlSpacer />
                  <v-btn
                    dark
                    id="ButtonAnimationControls"
                    class="fullscreen-name"
                    style="cursor: pointer"
                    @click.stop="FullScreen(fullScreen)"
                  >
                    <v-img
                      src="@/assets/images/player-fullscreen-icon.svg"
                      width="32"
                      height="24"
                      class="mx-auto"
                    ></v-img>
                  </v-btn>
                </ControlGroup>
              </div>
            </Controls>
          </Ui>
        </Player>
      </div>

      <template>
        <v-card width="100%">
          <div class="scrollbar-container">
            <div class="video-scrollbar">
              <v-row class="mr-1 mt-2 alert-row">
                <div
                  v-if="showAlertMessage"
                  style="
                    color: red;
                    font-weight: bold;
                    text-align: right;
                    width: 100%;
                  "
                >
                  {{ $t("videofilenotrewinded") }}
                </div>
              </v-row>
              <v-card-title class="pt-2 pb-2 videoTitle-font">
                {{ videoName }}
              </v-card-title>

              <v-row no-gutters>
                <v-col
                  cols="12"
                  class="pl-3"
                  style="
                    display: flex;
                    justify-content: center;
                    vertical-align: middle;
                  "
                >
                  <v-avatar size="45" class="marginRight-6">
                    <v-img
                      v-if="profileImg === null"
                      src="@/assets/defaultprofile.png"
                    ></v-img>
                    <v-img v-else :src="profileImg"></v-img>
                  </v-avatar>
                  <v-text-field
                    v-model="new_comment"
                    :label="$t('writeacomment')"
                    outlined
                    @input="checkCommentLength(new_comment, 1)"
                    dense
                    class="mt-1 comment-textbox"
                    hide-details="auto"
                    maxlength="100"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  class="py-3"
                  style="display: flex; justify-content: end"
                >
                  <v-btn
                    depressed
                    text
                    outlined
                    class="text-capitalize ml-5"
                    style="border-radius: 8px; font-size: 14px; width: 80px; height: 35px;"
                    @click="(new_comment = ''), (mainComNull = true)"
                  >
                    {{ $t("cancel") }}
                  </v-btn>
                  <v-btn
                    depressed
                    :disabled="mainComNull"
                    width="80"
                    height="35"
                    class="text-capitalize btn_hover_effect ml-5"
                    style="border-radius: 8px; font-size: 14px;
                     width: 80px; height: 35px; color: #ffff;"
                    color="#A6CC39"
                    @click="AddNewComment()"
                  >
                    {{ $t("send") }}
                  </v-btn>
                </v-col>
              </v-row>
              <div class="comment-paddingLeft4">
                <p class="mb-0" style="font-weight: bold; color: black">
                  {{ commentItems.length != 0 ? commentItems.length : "0" }}
                  {{ $t("comments") }}
                </p>
              </div>
              <div
                class="comment-paddingLeft4 pt-3"
                v-for="(comment, index) in commentItems"
                :key="index"
              >
                <v-row no-gutters>
                  <!-- outer comment part -->
                  <v-col cols="11" md="11" style="display: flex">
                    <v-row no-gutters>
                      <v-col
                        cols="11"
                        md="11"
                        style="display: flex; flex-direction: row"
                      >
                        <v-avatar
                          justify="center"
                          align="center"
                          size="35"
                          class="marginRight-6"
                        >
                          <v-img
                            v-if="comment.profileImage"
                            :src="comment.profileImage"
                          ></v-img>
                          <v-img
                            v-else
                            src="@/assets/defaultprofile.png"
                          ></v-img>
                        </v-avatar>
                        <!-- show comment/show edit text-box -->
                        <div class="d-flex flex-column" style="width: 100%">
                          <div v-if="!comment.editCommentFlag">
                            <p class="mb-1">
                              {{ comment.fullname }}
                              {{ comment.commentedTime }}
                              <br />
                              {{ comment.commentMessage }}
                            </p>
                          </div>
                          <!-- when edit comment show this -->
                          <div v-else>
                            <v-row no-gutters>
                              <v-col cols="12" class="d-flex">
                                <v-text-field
                                  :placeholder="$t('writeacomment')"
                                  outlined
                                  dense
                                  v-model="comment.tempComment"
                                  @input="
                                    checkCommentLength(comment.tempComment, 2)
                                  "
                                  hide-details="auto"
                                  maxlength="100"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row class="mt-0">
                              <v-col
                                style="
                                  display: flex;
                                  justify-content: end;
                                  align-items: center;
                                "
                              >
                                <v-btn
                                  depressed
                                  text
                                  outlined
                                  class="text-capitalize"
                                  style="border-radius: 8px; font-size: 14px; width: 80px; height: 35px;"
                                  @click="cancelComment(comment)"
                                >
                                  {{ $t("cancel") }}
                                </v-btn>
                                <v-btn
                                  depressed
                                  :disabled="commentNull"
                                  width="100"
                                  height="35"
                                  class="text-capitalize btn_hover_effect ml-5"
                                  style="border-radius: 8px; font-size: 14px; width: 80px; height: 35px; 
                                  color: #ffff; padding: 0.3rem 0px 0.3rem 0px;"
                                  color="#A6CC39"
                                  @click="UpdateComment(comment)"
                                >
                                  {{ $t("update") }}
                                </v-btn>
                              </v-col>
                            </v-row>
                          </div>
                          <!-- reply btn under each comment -->
                          <div class="pt-1">
                            <v-btn
                              style="
                                font-size: 14px;
                                min-width: 54px;
                                min-height: 22px;
                                height: auto !important;
                                justify-content: center;
                                background-color: #f0f0f0;
                                opacity: 0.42;
                                color: #424242;
                                opacity: 1;
                                border-radius: 4px;
                                padding: 0;
                              "
                              text
                              class="text-capitalize"
                              @click="ClickCommentReply(comment)"
                            >
                              {{ $t("reply") }}
                            </v-btn>
                          </div>
                          <!-- show replies count text under reply btn -->
                          <div
                            v-if="comment.replyCommentLists.length != 0"
                            @click="showReplylist(comment)"
                          >
                            <p
                              style="
                                color: blue;
                                cursor: pointer;
                                font-size: 14px;
                                text-transform: lowercase;
                              "
                              class="mb-1"
                            >
                              <v-icon
                                style="color: blue"
                                v-if="comment.replyFlag"
                                >mdi-chevron-down</v-icon
                              >
                              <v-icon v-else style="color: blue"
                                >mdi-chevron-up</v-icon
                              >{{ comment.replyCommentLists.length }}
                              {{
                                comment.replyCommentLists.length > 1
                                  ? $t("replies")
                                  : $t("reply")
                              }}
                            </p>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <!-- dot actions for editing comments -->
                  <v-col
                    v-if="
                      comment.memberID == loginmemberID ||
                      loginmemberID == memberID
                    "
                    cols="1"
                    md="1"
                    class="px-0"
                    style="display: flex; justify-content: end"
                  >
                    <template>
                      <div class="d-flex" style="margin-top: -5px">
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              text
                              v-bind="attrs"
                              v-on="on"
                              style="
                                min-width: 32px !important;
                                padding: 0px !important;
                              "
                            >
                              <v-icon style="color: #424242"
                                >mdi-dots-vertical</v-icon
                              >
                            </v-btn>
                          </template>

                          <v-list class="d-flex flex-column align-start">
                            <div>
                              <v-list-item
                                class="list-item-padding"
                                v-show="loginmemberID == comment.memberID"
                              >
                                <v-btn
                                  color="#424242"
                                  style="width: 100px; justify-content: left"
                                  text
                                  class="text-capitalize"
                                  @click="editComment(comment)"
                                >
                                  <v-icon left>mdi-square-edit-outline</v-icon>
                                  {{ $t("edit") }}
                                </v-btn>
                              </v-list-item>
                              <v-list-item class="list-item-padding">
                                <v-btn
                                  color="#FF6060"
                                  style="width: 100px; justify-content: left"
                                  text
                                  class="text-capitalize"
                                  @click="prepareDeleteComment(comment)"
                                >
                                  <v-icon left>mdi-delete-outline</v-icon>
                                  {{ $t("delete") }}
                                </v-btn>
                              </v-list-item>
                            </div>
                          </v-list>
                        </v-menu>
                      </div>
                    </template>
                  </v-col>
                </v-row>
                <!-- reply text box to outer comment -->
                <v-row
                  no-gutters
                  v-if="comment.showOuterCommentBox"
                  class="mt-3"
                >
                  <v-col
                    cols="11"
                    class="reply-textbox-paddingLeft d-flex flex-column"
                  >
                    <div class="d-flex" style="width: 100%">
                      <v-avatar
                        class="marginRight-6"
                        justify="center"
                        align="center"
                        size="40"
                      >
                        <v-img
                          v-if="profileImg === null"
                          src="@/assets/defaultprofile.png"
                        ></v-img>
                        <v-img v-else :src="profileImg"></v-img>
                      </v-avatar>
                      <div class="d-flex" style="width: 100%">
                        <v-text-field
                          :placeholder="$t('writeareply')"
                          v-model="comment.tempReplyMsg"
                          @input="checkCommentLength(comment.tempReplyMsg, 3)"
                          class="comment-textbox"
                          dense
                          hide-details="auto"
                          maxlength="100"
                        ></v-text-field>
                      </div>
                    </div>
                    <div class="mt-3 d-flex justify-end" style="width: 100%">
                      <v-btn
                        depressed
                        text
                        outlined
                        class="text-capitalize ml-5"
                        style="border-radius: 8px; font-size: 14px; width: 80px; height: 35px;"
                        @click="
                          (comment.tempReplyMsg = ''),
                            (mainComNull = true),
                            (comment.showOuterCommentBox = false)
                        "
                      >
                        {{ $t("cancel") }}
                      </v-btn>
                      <v-btn
                        depressed
                        :disabled="replyCommentNull"
                        class="text-capitalize btn_hover_effect ml-5"
                        style="border-radius: 8px; font-size: 14px; 
                        width: 80px; height: 35px; color: #ffff;"
                        color="#A6CC39"
                        @click="AddReplyToComment(comment, null)"
                      >
                        {{ $t("reply") }}
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <!-- replies of each related comment -->
                <v-row
                  class="mt-1 comment-paddingLeft4 ml-1"
                  v-for="(repcomment, repIndex) in comment.replyCommentLists"
                  :key="repIndex"
                  v-show="comment.replyFlag"
                >
                  <v-col
                    cols="11"
                    md="11"
                    class="reply-textbox-paddingLeft d-flex flex-column pr-1"
                  >
                    <div class="d-flex" style="width: 100%">
                      <v-avatar
                        class="marginRight-6"
                        justify="center"
                        align="center"
                        size="35"
                      >
                        <v-img
                          v-if="repcomment.profileImage"
                          :src="repcomment.profileImage"
                        ></v-img>
                        <v-img v-else src="@/assets/defaultprofile.png"></v-img>
                      </v-avatar>
                      <div class="d-flex flex-column" style="width: 100%">
                        <p class="mb-0">
                          {{ repcomment.fullname }}
                          {{ repcomment.commentedTime }}
                        </p>
                        <div
                          v-if="!repcomment.showInside_ReplyBox"
                          style="display: flex; align-items: center"
                        >
                          <p
                            style="color: #34b4eb; margin-right: 10px"
                            class="mb-0"
                          >
                            {{ repcomment.replyFullname }}
                          </p>
                        </div>
                        <div v-if="!repcomment.showEditReplyBox" class="mb-2">
                          {{ repcomment.replyMessage }}
                        </div>
                        <div v-else>
                          <div>
                            <v-text-field
                              :placeholder="$t('writeareply')"
                              v-model="repcomment.tempEditReplyText"
                              @input="
                                checkCommentLength(
                                  repcomment.tempEditReplyText,
                                  4
                                )
                              "
                              class="comment-textbox"
                              dense
                              hide-details="auto"
                              maxlength="100"
                            ></v-text-field>
                          </div>
                          <div
                            class="mt-3 d-flex justify-end"
                            style="width: 100%"
                          >
                            <v-btn
                              depressed
                              text
                              outlined
                              class="text-capitalize"
                               style="border-radius: 8px; font-size: 14px; width: 80px;
                                height: 35px;"
                              @click="
                                () => {
                                  repcomment.showEditReplyBox = false;
                                  repcomment.tempEditReplyText = '';
                                }
                              "
                            >
                              {{ $t("cancel") }}
                            </v-btn>
                            <v-btn
                              depressed
                              :disabled="editReplyNull"
                              width="100"
                              height="35"
                              class="text-capitalize btn_hover_effect ml-5"
                              style="border-radius: 8px; font-size: 14px;
                               width: 80px; height: 35px; color: #ffff; padding: 0.3rem 0px 0.3rem 0px;"
                             
                              color="#A6CC39"
                              @click="UpdateReplyText(repcomment, comment)"
                            >
                              {{ $t("update") }}
                            </v-btn>
                          </div>
                        </div>
                        <div>
                          <v-btn
                            style="
                              font-size: 14px;
                              min-width: 54px;
                              min-height: 22px;
                              height: auto !important;
                              justify-content: center;
                              background-color: #f0f0f0;
                              opacity: 0.42;
                              color: #424242;
                              opacity: 1;
                              border-radius: 4px;
                              padding: 0;
                            "
                            text
                            class="text-capitalize"
                            v-if="!repcomment.showInside_ReplyBox"
                            @click="replyToInnerComment(repcomment)"
                          >
                            {{ $t("reply") }}
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <!-- dot action btns of inner reply comments -->
                  <v-col
                    cols="1"
                    md="1"
                    class="pl-0"
                    style="display: flex; justify-content: end"
                    v-if="
                      repcomment.memberID == loginmemberID ||
                      loginmemberID == memberID
                    "
                  >
                    <template>
                      <div class="d-flex" style="margin-top: -5px">
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              text
                              v-bind="attrs"
                              v-on="on"
                              class="mr-0"
                              style="
                                min-width: 32px !important;
                                padding: 0 !important;
                              "
                            >
                              <v-icon style="color: #424242"
                                >mdi-dots-vertical</v-icon
                              >
                            </v-btn>
                          </template>
                          <v-list class="d-flex flex-column align-start">
                            <div>
                              <v-list-item
                                class="list-item-padding"
                                v-show="loginmemberID == repcomment.memberID"
                              >
                                <v-btn
                                  color="#424242"
                                  style="
                                    font-size: 16px;
                                    width: 100px;
                                    justify-content: left;
                                  "
                                  text
                                  class="text-capitalize"
                                  @click="editCommentReply(repcomment, comment)"
                                >
                                  <v-icon left>mdi-square-edit-outline</v-icon>
                                  {{ $t("edit") }}
                                </v-btn>
                              </v-list-item>
                              <v-list-item class="list-item-padding">
                                <v-btn
                                  color="#FF6060"
                                  style="width: 100px; justify-content: left"
                                  text
                                  class="text-capitalize"
                                  @click="prepareDeleteReply(repcomment)"
                                >
                                  <v-icon left>mdi-delete-outline</v-icon>
                                  {{ $t("delete") }}
                                </v-btn>
                              </v-list-item>
                            </div>
                          </v-list>
                        </v-menu>
                      </div>
                    </template>
                  </v-col>
                  <!-- inner reply box -->
                  <v-col
                    cols="12"
                    md="12"
                    class="reply-textbox-paddingLeft"
                    v-if="repcomment.showInside_ReplyBox"
                    style="display: flex; justify-content: center"
                  >
                    <v-row no-gutters>
                      <v-col
                        cols="11"
                        class="reply-textbox-paddingLeft d-flex flex-column"
                      >
                        <div class="d-flex" style="width: 100%">
                          <v-avatar
                            class="marginRight-6"
                            justify="center"
                            align="center"
                            size="40"
                          >
                            <v-img
                              v-if="profileImg === null"
                              src="@/assets/defaultprofile.png"
                            ></v-img>
                            <v-img v-else :src="profileImg"></v-img>
                          </v-avatar>
                          <div class="d-flex" style="width: 100%">
                            <v-text-field
                              :placeholder="$t('writeareply')"
                              v-model="repcomment.tempCommentReplyMsg"
                              @input="
                                checkCommentLength(
                                  repcomment.tempCommentReplyMsg,
                                  5
                                )
                              "
                              class="comment-textbox"
                              dense
                              hide-details="auto"
                              maxlength="100"
                            ></v-text-field>
                          </div>
                        </div>
                        <div
                          class="mt-3 d-flex justify-end"
                          style="width: 100%"
                        >
                          <v-btn
                            depressed
                            text
                            outlined
                            class="text-capitalize ml-5"
                            style="border-radius: 8px; font-size: 14px; width: 80px;
                             height: 35px;margin-left: 5px"
                            @click="closeReplyMessage(repcomment)"
                          >
                            {{ $t("cancel") }}
                          </v-btn>
                          <v-btn
                             depressed
                            :disabled="replyInnerComNull"
                            class="text-capitalize btn_hover_effect ml-5"
                            style="border-radius: 8px; font-size: 14px;
                             width: 80px; height: 35px; color: #ffff;"
                            color="#A6CC39"
                            @click="AddReplyToComment(comment, repcomment)"
                          >
                            {{ $t("reply") }}
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </v-card>
      </template>
    </v-dialog>
    <!-- Popup Alert For Message Check -->
    <v-dialog
      v-model="delete_dialog"
      max-width="470px"
      max-height="87px"
      style="border-radius: 15px !important"
    >
      <v-card style="border-radius: 15px !important">
        <v-spacer></v-spacer>
        <v-card-title class="flex justify-center items-center mb-4">
          <p class="mb-0" style="font-size: 20px; font-weight: bold">
            {{ $t("deletecomment") }}
          </p>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="closemessageDialog()"
            color="#424242"
            style="
              border-radius: 8px !important;
              border: 1px solid rgba(66, 66, 66, 0.05);
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="text-left">
          <p
            v-if="deleteType === 'comment'"
            style="font-size: 20px; color: #363636; opacity: 1"
            class="mb-1"
          >
            {{ $t("doyouwanttodeletecomment") }}
          </p>
          <p
            v-else
            style="font-size: 20px; color: #363636; opacity: 1"
            class="mb-1"
          >
            {{ $t("doyouwanttodeletereplycomment") }}
          </p>
        </v-card-text>
        <v-divider class="mb-2" style="opacity: 0.5"></v-divider>
        <v-card-actions class="justify-end" style="padding-bottom: 15px">
          <v-btn
            width="70"
            height="39"
            class="text-capitalize ml-5"
            style="border-radius: 8px; font-size: 14px"
            color="#F0F0F0 "
            @click="closemessageDialog()"
            >{{ $t("No") }}</v-btn
          >
          <v-btn
            width="76"
            height="39"
            class="text-capitalize btn_hover_effect ml-5"
            style="
              color: #ffff;
              border: 1px solid #a6cc39;
              border-radius: 8px;
              font-size: 14px;
            "
            color="#A6CC39"
            @click="
              deleteType === 'comment'
                ? DeleteComment(deleteComTemp.commentID)
                : DeleteReply(deleteReplyTemp.replyID)
            "
            >{{ $t("Yes") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <v-dialog v-model="update_Dialog" max-width="450px">
      <v-card>
        <v-card-title> Are you sure update </v-card-title>
        <v-card-actions>
          <v-btn @click="closemessageDialog()">{{ $t("cancel") }}</v-btn>
          <v-btn>Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <SuccessDialog
      :show="successDialog"
      :title="$t('success')"
      :text="message"
      @close="successDialog = false"
    />
    <!-- <SuccessDialog
      :show="deletesuccessDialog"
      :title="$t('success')"
      :text="$t('deletecommentsuccessful')"
      @close="(deletesuccessDialog = false), GetcommendData(contentid, Page)"
    />
    <SuccessDialog
      :show="addsuccessDialog"
      :title="$t('success')"
      :text="$t('addcommentsuccessful')"
      @close="(addsuccessDialog = false), GetcommendData(contentid, Page)"
    /> 
    <SuccessDialog
      :show="editsuccessDialog"
      title="Success"
      :text="$t('updatecommentsuccess')"
      @close="(editsuccessDialog = false), GetcommendData(contentid, Page)"
    /> -->
    <v-dialog width="470" v-model="exit_dialog" persistent>
      <v-card style="border-radius: 16px !important">
        <v-card-title style="font-size: 20px; color: #363636">
          <v-row>
            <v-col cols="10" md="10" lg="10" xl="10" class="pr-1 pl-1">
              <p class="mb-2">{{ $t("Videonotfinish") }}</p>
            </v-col>
            <v-col
              cols="2"
              md="2"
              lg="2"
              xl="2"
              style="text-align: right"
              class="pr-1 pl-1"
            >
              <v-btn
                icon
                @click="cancelExitPlayer()"
                color="#424242"
                class="mt-2"
                style="
                  border-radius: 8px !important;
                  border: 1px solid rgba(66, 66, 66, 0.05);
                  top: -8px;
                "
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize mb-2 mt-2"
            text
            style="
              border: 1px solid #c0c0c0;
              border-radius: 8px;
              color: #424242;
              font-size: 20px;
            "
            @click="cancelExitPlayer()"
            >{{ $t("No") }}</v-btn
          >
          <v-btn
            color="#A6CC39"
            style="font-size: 20px; border-radius: 8px; width: auto !important"
            class="text-capitalize white--text mb-2 mt-2 btn_hover_effect"
            @click="exitPlayer()"
            >{{ $t("Yes") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog width="470" v-model="complete_dialog" persistent>
      <v-card
        style="overflow: hidden !important; border-radius: 16px !important"
      >
        <v-card-title style="font-size: 20px; color: #363636">
          <v-row class="mt-1 mb-1">
            <v-col style="text-align: center">
              <p class="mb-2">{{ $t("confirmcomplete") }}</p>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-actions style="justify-content: center">
          <v-btn
            @click="viewComplete()"
            color="#A6CC39"
            style="font-size: 20px; border-radius: 8px; width: auto !important"
            class="text-capitalize white--text mb-2 mt-2 btn_hover_effect"
            >{{ $t("confirm") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Popup Alert For View Check -->
    <v-dialog
      width="365"
      v-model="popupAlertforViewCheck"
      persistent
      scrollable
    >
      <v-card style="overflow: hidden; border-radius: 12px !important">
        <v-card-title>
          <p style="font-size: 20px; color: #363636" class="mt-0 mb-0">
            {{ $t("videopausewanttocontinuewatching") }}
          </p>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-actions style="justify-content: center">
          <v-btn
            @click="
              (popupAlertforViewCheck = false),
                (turnOffPopupViewCheckAlert = true),
                $refs.player.play(),
                (popupTimerCountInSec = 0)
            "
            color="#A6CC39"
            style="font-size: 20px; border-radius: 8px"
            class="text-capitalize white--text mt-3 mb-1"
          >
            {{ $t("ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import SuccessDialog from "@/components/SuccessDialog.vue";

import {
  Player,
  Video,
  Ui,
  Scrim,
  ControlGroup,
  //ScrubberControl,
  Controls,
  ControlSpacer,
  CurrentTime,
  EndTime,
} from "@vime/vue";
export default {
  components: {
    Player,
    Video,
    Ui,
    Scrim,
    ControlGroup,
    //ScrubberControl,
    Controls,
    ControlSpacer,
    CurrentTime,
    EndTime,
    SuccessDialog,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    viewerFlag: Boolean,
    formattedDateTime: String,
    videoName: String,
    //Addfav: Boolean,
    //request_flag: Boolean,
    videoDescription: String,
    videoFile: String,
    contentid: Number,
    courseID: Number,
    userID: Number,
    userName: String,
    lastWatchedTime: String,
    popupTime: Number,
    memberID: String,
    video_page: Number,
  },
  data() {
    return {
      showAlertMessage: false,
      alertVisible: false,
      //...comment related......
      deleteReplyTemp: null,
      deleteComTemp: null,
      deleteType: null,
      delete_dialog: false,
      loginmemberID: localStorage.getItem("memberID"),
      new_comment: "",
      commentItems: [],
      commentNull: true,
      replyCommentNull: true,
      mainComNull: true,
      editReplyNull: true,
      replyInnerComNull: true,
      profileImg: localStorage.getItem("profileimage"),
      //......................
      message: "",
      user_ID: parseInt(localStorage.getItem("UserID")),
      user_Name: localStorage.getItem("userName"),
      popupTimerCountInSec: 0,
      turnOffPopupViewCheckAlert: false,
      popupAlertforViewCheck: false,
      loadVideo: true,
      buffering: false,
      PlayBtn: true,
      dragging: false,
      finishVideo: false,
      fullScreen: false,
      duration: -1,
      progress_time: 0,
      centisecond: 0,
      seeking: false,
      actualPlayTime: 0, // work as seconds
      playing: false,
      restart: false, //use for restart the video
      currentTime: 0,
      playback_started: false,
      isFullscreenBefore: false,
      isFullscreen: false,
      exit_dialog: false,
      complete_dialog: false,
      isFavourite: false,
      Videolink: "",
      mPlay: true,
      successDialog: false,
      //update_Dialog: false,
      //deletesuccessDialog: false,
      //editsuccessDialog: false,
      //addsuccessDialog: false,
      //currentDate: new Date().toLocaleString(),
    };
  },
  computed: {
    progressPercentage() {
      return (this.currentTime / this.duration) * 100 + "%";
    },
    player() {
      return this.$refs.player;
    },
    videoPlayerDialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },
  },
  methods: {
    //.......comment related..............................
    async DeleteReply(ID) {
      let self = this;
      await axios
        .post(`${self.web_url}Comment/DeleteReplyComment?replyID=` + ID)
        .then(function (res) {
          if (res.data.status == 0) {
            self.delete_dialog = false;
            self.GetCommentsForVideo(self.contentid);
          }
        })
        .catch(function (err) {
          alert(err);
        });
    },
    async DeleteComment(ID) {
      let self = this;
      await axios
        .post(`${self.web_url}Comment/DeleteComment?CommentID=` + ID)
        .then(function (res) {
          if (res.data.data == 0) {
            self.delete_dialog = false;
            self.deleteComTemp = null;
            self.GetCommentsForVideo(self.contentid);
          }
        })
        .catch(function (err) {
          alert(err);
        });
    },
    async UpdateReplyText(reply, comment) {
      let self = this;
      self.editReplyNull = true;
      const request = {
        replyID: reply.replyID,
        replyMessage: reply.tempEditReplyText,
      };
      axios
        .post(`${self.web_url}Comment/UpdateReplyComment`, request)

        .then(function (result) {
          if (result.data.status == 0) {
            comment.replyFlag = false;
            self.GetCommentsForVideo(self.contentid);
          }
          self.commentNull = false;
        });
    },
    AddReplyToComment(item, reply) {
      let self = this;
      self.replyCommentNull = true;
      self.replyInnerComNull = true;
      let tempReplyID = reply?.replyID || null;
      if (reply != null && reply.memberID == self.loginmemberID) {
        tempReplyID = null;
      }
      let tempReply_msg = reply?.tempCommentReplyMsg || "";
      const request = {
        userID: parseInt(localStorage.getItem("UserID")),
        commentID: item.commentID,
        replyMessage: reply != null ? tempReply_msg : item.tempReplyMsg,
        replyID: tempReplyID,
      };
      axios
        .post(`${self.web_url}Comment/AddReplyComment`, request)

        .then(function (result) {
          if (result.data.status == 0) {
            self.GetCommentsForVideo(self.contentid);
          }
          self.commentNull = false;
        });
    },
    async UpdateComment(update_value) {
      let self = this;
      self.commentNull = true;
      const request = {
        commentID: update_value.commentID,
        commentMessage: update_value.tempComment,
      };
      axios
        .post(`${self.web_url}Comment/UpdateComment`, request)

        .then(function (result) {
          if (result.data.status == 0) {
            self.GetCommentsForVideo(self.contentid);
          }
        });
    },
    AddNewComment() {
      let self = this;
      self.mainComNull = true;
      const request = {
        companyID: localStorage.getItem("companyID"),
        userID: parseInt(localStorage.getItem("UserID")),
        videoID: self.contentid,
        updateBy: null,
        createdBy: localStorage.getItem("memberID"),
        commentMessage: self.new_comment,
        Page: self.video_page, //for sound page comment
      };
      axios
        .post(`${self.web_url}Comment/AddComment`, request)

        .then(function (result) {
          if (result.data.status == 0) {
            self.GetCommentsForVideo(self.contentid);
          }
        });
      self.new_comment = "";
    },
    prepareDeleteReply(repcomment) {
      let self = this;
      self.deleteType = "reply";
      self.delete_dialog = true;
      self.deleteReplyTemp = repcomment;
    },
    closemessageDialog() {
      this.delete_dialog = false;
    },
    async prepareDeleteComment(comment) {
      let self = this;
      self.delete_dialog = true;
      self.deleteComTemp = comment;
      self.deleteType = "comment";
    },
    closeReplyMessage(item) {
      item.showInside_ReplyBox = false;
      item.tempCommentReplyMsg = "";
      this.commentNull = false;
    },
    replyToInnerComment(repcomment) {
      let self = this;
      self.commentItems.forEach((element) => {
        element.replyCommentLists.forEach((item) => {
          (item.showInside_ReplyBox = false),
            (item.showEditReplyBox = false),
            (item.tempEditReplyText = "");
        });
      });
      repcomment.showInside_ReplyBox = true;
      self.replyInnerComNull = true;
    },
    editCommentReply(repcomment, comment) {
      let self = this;

      self.commentItems.forEach((element) => {
        element.replyCommentLists.forEach((item) => {
          (item.showInside_ReplyBox = false),
            (item.showEditReplyBox = false),
            (item.tempEditReplyText = "");
        });
      });

      self.commentItems[comment.no - 1].replyFlag = true;
      self.commentItems[comment.no - 1].replyCommentLists[
        repcomment.no - 1
      ].showEditReplyBox = true;
      self.commentItems[comment.no - 1].replyCommentLists[
        repcomment.no - 1
      ].tempEditReplyText = repcomment.replyMessage;
      self.editReplyNull = repcomment.replyMessage.length === 0;
    },
    cancelComment(com) {
      com.editCommentFlag = false;
    },
    ClickCommentReply(comment) {
      let self = this;
      self.commentItems.forEach((element) => {
        element.editCommentFlag = false;
        element.replyFlag = false;
        element.showOuterCommentBox = false;
        element.tempReplyMsg = "";
        element.replyCommentLists.forEach((item) => {
          (item.showInside_ReplyBox = false),
            (item.showEditReplyBox = false),
            (item.tempEditReplyText = ""),
            (item.tempCommentReplyMsg = "");
        });
      });
      self.commentItems[comment.no - 1].showOuterCommentBox = true;
      self.replyCommentNull = true;
    },
    editComment(com) {
      let self = this;
      self.commentItems.forEach((element) => {
        element.editCommentFlag = false;
        element.replyFlag = false;
        element.showOuterCommentBox = false;
        element.replyCommentLists.forEach((item) => {
          (item.showInside_ReplyBox = false),
            (item.showEditReplyBox = false),
            (item.tempEditReplyText = ""),
            (item.tempCommentReplyMsg = "");
        });
      });

      self.commentItems[com.no - 1].editCommentFlag = true;
      self.commentItems[com.no - 1].tempComment = com.commentMessage;
      self.commentNull = com.commentMessage.length === 0;
      com.editCommentFlag = true;
    },
    showReplylist(comment) {
      comment.replyFlag = !comment.replyFlag;
    },
    checkCommentLength(v, type) {
      if (v.length > 99) {
        alert("Message should be no more than 100 characters");
      }
      switch (type) {
        case 1:
          this.mainComNull = v.length === 0;
          break;
        case 2:
          this.commentNull = v.length === 0;
          break;
        case 3:
          this.replyCommentNull = v.length === 0;
          break;
        case 4:
          this.editReplyNull = v.length === 0;
          break;
        case 5:
          this.replyInnerComNull = v.length === 0;
          break;
      }
    },
    async GetCommentsForVideo(contentID) {
      let self = this;
      self.new_comment = "";
      console.log("vdo page: ", self.video_page);
      await this.$axios
        .get(
          `${self.web_url}Comment/GetComments?VideoID=${contentID}&Page=${self.video_page}`
        )
        .then(function (res) {
          console.log("all comments ", res.data.data);
          if (res.data.data.length > 0) {
            self.commentItems = res.data.data.map((v, i) => ({
              ...v,
              no: i + 1,
              replyFlag: false,
              editCommentFlag: false,
              showOuterCommentBox: false,
              showOuterReplyBox: false,
              tempComment: "",
              tempReplyMsg: "",
              replyCommentLists: v.replyCommentLists.map((j, k) => ({
                ...j,
                no: k + 1,
                tempEditReplyText: "",
                tempCommentReplyMsg: "",
                showEditReplyBox: false,
                showInside_ReplyBox: false,
              })),
            }));
          } else {
            self.commentItems = [];
          }
        });
    },
    //............................... end of comment related..........................

    formatDateTime(dateTime) {
      const options = {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
      };
      const dateTimeString = dateTime.toLocaleDateString("en-US", options);
      return dateTimeString.replace(/\//g, "-");
    },
    onVideoBuffering(buffering) {
      //show loading spinner when video is buffering
      this.loadVideo = buffering;
    },
    onReadytoPlay() {
      const player = this.$refs.player;
      if (player && player.pause) {
        setTimeout(() => {
          //resume video from last time watch
          if (this.lastWatchedTime != null || this.lastWatchedTime != "") {
            this.currentTime = parseInt(this.lastWatchedTime);
          } else {
            this.currentTime = 0;
          }

          player.play().catch((error) => {
            console.error("Failed to play video:", error);
          });
        }, 500);
      }
    },
    Readnoti(notificationid, pageid) {
      let self = this;
      const request = {
        userID: parseInt(localStorage.getItem("UserID")),
        notificationID: notificationid,
        notiPage: pageid,
      };
      axios
        .post(`${self.web_url}Notification/AddUserIDinNotiJoin`, request)

        .then(function (result) {
          if (result.data.status == 0) {
            self.getNotificationCount();
          }
        });
    },
    async getNotificationCount() {
      let self = this;
      const request = {
        userId: parseInt(localStorage.getItem("UserID")),
        companyId: localStorage.getItem("companyID"),
      };
      await axios
        .post(
          `${self.web_urlV3}Notification/GetAllNotificationMobileV4`,
          request
        )
        .then(function (result) {
          if (result.data.status == 0) {
            let d1 = result.data.data.filter(
              (x) => x.viewOrNot === false
            ).length;
            let d2 = result.data.data1.filter(
              (x) => x.newNoti === false
            ).length;
            let d3 = result.data.data2.filter(
              (x) => x.newNoti === false
            ).length;
            let d4 = result.data.data4.filter(
              (x) => x.newNoti === false
            ).length;
            let d5 = result.data.data5.filter(
              (x) => x.newNoti === false
            ).length;
            let d6 = result.data.data6.filter(
              (x) => x.newNoti === false
            ).length;
            let d7 = result.data.data7.filter(
              (x) => x.newNoti === false
            ).length;
            let d8 = result.data.data8.filter(
              (x) => x.newNoti === false
            ).length;
            let d9 = result.data.data3.filter(
              (x) => x.newNoti === false
            ).length;
            let notiCount = d1 + d2 + d3 + d4 + d5 + d6 + d7 + d8 + d9;
            self.$store.commit("savenotiCount", notiCount);
          }
        });
    },
    getFavorite(v) {
      this.isFavourite = v;
    },
    handleClick(event) {
      console.log("hello", event);
      if (!this.viewerFlag) {
        this.showAlert();
      } else {
        this.seekVideo(event);
      }
    },
    seekVideo(event) {
      const progressBar = event.currentTarget;
      const boundingRect = progressBar.getBoundingClientRect();
      const clickPosition = event.clientX - boundingRect.left;
      const progressRatio = clickPosition / boundingRect.width;
      const seekTime = this.duration * progressRatio;
      this.currentTime = seekTime;
      this.popupTimerCountInSec = 0; // Restart the popupAlert timer count
      // Seek the video to the new time
      console.log(`Seeking video to ${seekTime} seconds`);
    },
    showAlert() {
      this.showAlertMessage = true;
      this.alertVisible = true;
    },
    hideAlert() {
      this.showAlertMessage = false;
      this.alertVisible = false;
    },
    saveCurrentTime() {
      const video = this.$refs.player;
      localStorage.setItem(this.videoId, video.currentTime);
    },
    updateTime() {
      const video = this.$refs.player;
      this.progressPercentage =
        (video.currentTime / video.duration) * 100 + "%";
    },
    handleVideoEnded() {
      localStorage.removeItem(this.videoId);
      this.hideAlert();
    },
    onStartNewPlayback() {
      this.hideAlert();
    },
    onSeekedVideo() {
      if (!this.alertVisible) {
        this.hideAlert();
      }
    },
    // closeVideoDialog() {
    //   this.videoPlayerDialog = false;
    //   this.$refs.player.pause();
    // },
    async viewComplete() {
      let self = this;
      self.$emit("updateNotiReadStatusColor");
      let minute_data = "";
      self.popupTimerCountInSec = 0; //to restart the popupAlert timer count

      if (parseInt(self.duration) == parseInt(self.currentTime)) {
        // user finished watching..
        self.progress_time = "1";
        minute_data = self.currentTime;
      } else {
        let temp = self.currentTime / self.duration;
        //not to get 1 until the cofirm dialog is show
        if (temp.toFixed(1) == "1.0") {
          self.progress_time = parseFloat(temp) - 0.1;
          self.progress_time = self.progress_time.toFixed(1);
        } else {
          self.progress_time = temp.toFixed(1);
        }

        minute_data = parseInt(self.currentTime);
      }
      if (self.courseID == -1) {
        const request = {
          userID: parseInt(localStorage.getItem("UserID")),
          id: self.contentid, // video content id
          minutes: minute_data.toString(),
          separatePage: 5,
          progressTime: self.progress_time,
          actualViewTime: self.actualPlayTime.toString(),
        };
        axios
          .post(`${self.web_url}Reports/AddReportActivity`, request)
          .then((res) => {
            if (res.data.status == 0) {
              self.$emit("updateProgressCircle", request);
              self.complete_dialog = false;
              self.videoPlayerDialog = false;
              self.actualPlayTime = 0;
              self.currentTime = 0;

              // already moved this API call in AddReportActivity so no need to call it twice
              // const temp = {
              //   userID: parseInt(localStorage.getItem("UserID")),
              //   lessonID: this.contentid,
              // };
              // axios
              //   .post(`${self.web_url}GuidedLesson/UpdateCompleteVideo`, temp)
              //   .then(function (response) {
              //     if (response.data.status == 0) {
              //       self.$emit("updateProgressCircle", request);
              //       self.complete_dialog = false;
              //       self.videoPlayerDialog = false;
              //       self.actualPlayTime = 0;
              //       self.currentTime = 0;
              //     }
              //   })
              //   .catch((err) => {
              //     console.error(err);
              //   });
            }
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        // no need to update course's video's complete.
        const request = {
          id: 0,
          userID: parseInt(localStorage.getItem("UserID")),
          assignmentVideoID: self.contentid,
          assignmentCourseID: self.courseID,
          isComplete: true,
          progress: parseFloat(self.progress_time),
          lastViewTime: minute_data.toString(),
          actualViewTime: self.actualPlayTime.toString(),
        };
        axios
          .post(`${self.web_urlV5}Assignment/AddAssignmentReport`, request)
          .then(function (res) {
            if (res.data.status == 0) {
              self.$emit("updateProgressCircle"); //might not need in course..
              self.complete_dialog = false;
              self.videoPlayerDialog = false;
              self.actualPlayTime = 0;
              self.currentTime = 0;
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    async exitPlayer() {
      let self = this;
      self.popupTimerCountInSec = 0; //restart the popupAlert timer count
      self.$emit("updateNotiReadStatusColor");
      let complete_video = false;

      if (self.currentTime == 0) {
        self.exit_dialog = false;
        self.videoPlayerDialog = false;
        self.actualPlayTime = 0;
        self.currentTime = 0;
        return false;
      }

      let minute_data = "";

      if (parseInt(self.duration) == parseInt(self.currentTime)) {
        self.progress_time = "1";
        complete_video = true;

        minute_data = self.currentTime;
      } else {
        let temp = self.currentTime / self.duration;
        //not to get 1 until the cofirm dialog is show
        if (temp.toFixed(1) == "1.0") {
          self.progress_time = parseFloat(temp) - 0.1;
          self.progress_time = self.progress_time.toFixed(1);
        } else {
          self.progress_time = temp.toFixed(1);
        }

        minute_data = parseInt(self.currentTime);
      }
      if (self.courseID == -1) {
        const request = {
          userID: parseInt(localStorage.getItem("UserID")),
          id: self.contentid,
          minutes: minute_data.toString(),
          separatePage: 5,
          progressTime: self.progress_time,
          actualViewTime: self.actualPlayTime.toString(),
        };
        axios
          .post(`${self.web_url}Reports/AddReportActivity`, request)
          .then((res) => {
            if (res.data.status == 0) {
              self.$emit("updateProgressCircle", request);
              self.exit_dialog = false;
              self.videoPlayerDialog = false;
              self.actualPlayTime = 0;
              self.currentTime = 0;
            }
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        const request = {
          id: 0,
          userID: parseInt(localStorage.getItem("UserID")),
          assignmentVideoID: self.contentid,
          assignmentCourseID: self.courseID,
          isComplete: complete_video,
          progress: parseFloat(self.progress_time),
          lastViewTime: minute_data.toString(),
          actualViewTime: self.actualPlayTime.toString(),
        };
        axios
          .post(`${self.web_urlV5}Assignment/AddAssignmentReport`, request)
          .then(function (res) {
            if (res.data.status == 0) {
              self.$emit("updateProgressCircle"); //might not need in course..
              self.exit_dialog = false;
              self.videoPlayerDialog = false;
              self.actualPlayTime = 0;
              self.currentTime = 0;
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    formatTime(t_seconds) {
      const minutes = Math.floor(t_seconds / 60);
      const remainingSeconds = (t_seconds % 60).toFixed(1);
      return `${minutes}:${remainingSeconds.padStart(4, "0")}`;
    },
    formatTimeForMinuteData(t_seconds) {
      const minutes = Math.floor(t_seconds / 60);
      const seconds = Math.floor(t_seconds % 60)
        .toString()
        .padStart(2, "0");
      return `${minutes}:${seconds}`;
    },
    cancelExitPlayer() {
      this.exit_dialog = false;
      if (this.isFullscreenBefore === true) {
        this.$refs.player.enterFullscreen();
      }
      this.$refs.player.play();
    },
    showExitVideoPlayerDialog() {
      if (this.isFullscreen === true) {
        this.$refs.player.exitFullscreen();
      }
      this.isFullscreenBefore = this.isFullscreen;
      if (this.currentTime == this.duration) {
        this.complete_dialog = true; // already finish the video
      } else {
        this.exit_dialog = true; // not finish the video
      }
      this.$refs.player.pause(); // if the video is playing, stop to show the dialog
      this.popupTimerCountInSec = 0;
    },
    onDurationChange(duration) {
      // get total time of video in seconds
      this.duration = duration;
    },
    onPlaybackEnded() {
      if (this.currentTime != 0) {
        this.complete_dialog = true;
      } else {
        this.complete_dialog = false;
      }
      this.popupTimerCountInSec = 0; //restart the popupAlert timer count
    },
    // onStartNewPlayback() {
    //   this.complete_dialog = false;
    //   this.popupTimerCountInSec = 0; //restart the popupAlert timer count
    // },
    likeVideo() {
    console.log("hello");
      this.isFavourite = !this.isFavourite;
      this.updateFavourite(this.isFavourite);
    },
    updateFavourite(Favourite) {
      let self = this;
      const request = {
        userID: parseInt(localStorage.getItem("UserID")),
        contentID: this.contentid,
        contentCheck: 3, //content type => article = 1, sound = 2 , video = 3 and knowledgesharing = 4
        favFlag: Favourite,
      };
      axios
        .post(`${this.web_url}Contents/UpdateFavorite`, request)
        .then(function (res) {
          if (res.data.status == 0) {
            if (Favourite === true) {
              self.$store.commit(
                "savefavCount",
                self.$store.state.library.favCount + 1
              );
            } else {
              self.$store.commit(
                "savefavCount",
                self.$store.state.library.favCount - 1
              );
            }
          }
        });
    },
    playVideo(play) {
      this.playing = play === false ? true : false;
      if (this.playing) {
        this.$refs.player.play();
      } else {
        this.$refs.player.pause();
      }
      this.popupTimerCountInSec = 0; //when click play/pause btn restart the popupAlert timer count
    },
    FullScreen(full) {
      this.fullScreen = full === false;
      if (this.fullScreen) {
        this.$refs.player.enterFullscreen();
        this.popupTimerCountInSec = 0; //restart the popupAlert timer count
      } else {
        this.$refs.player.exitFullscreen();
        this.popupTimerCountInSec = 0; //restart the popupAlert timer count
      }
    },
    restartVideo() {
      this.currentTime = 0;
      this.restart = true;
      this.popupTimerCountInSec = 0; //restart the popupAlert timer count
    },
    onPlayingChange(play) {
      this.playing = play;
      this.PlayBtn = !play;
      if (this.playing) {
        this.loadVideo = false;
      }
    },
    // onSeekedVideo(v) {
    //   this.seeking = v;
    //   this.popupTimerCountInSec = 0; //restart the popupAlert timer count
    // },
    onTimeUpdate(time) {
      this.currentTime = time;
      this.centisecond += 1;

      if (this.restart === true) {
        this.currentTime = 0;
        this.centisecond = 0;
        this.popupTimerCountInSec = 0; //if video restart popup timer will restart
        if (this.playing === false) {
          this.$refs.player.play();
        }
      }

      if (this.centisecond == 60) {
        this.actualPlayTime += this.seeking === false ? 1 : 0;
        this.centisecond = 0;

        //start count popup timer. 60 centisecond is equal to 1s
        this.popupTimerCountInSec += 1;
      }
      this.seeking = false;

      //Popup Alert Timer Condition
      //Start counting from where the video is start playing..(eg.video start from 12 seconds it will start count from there)
      //If taking any action like clicking on any btns and showing other popup dialogs will make the timer to restart the count..
      //Even after close popupAlert timer, the timer will start counting again and show it until the video is finish..
      if (!this.turnOffPopupViewCheckAlert && this.popupTime !== 0) {
        if (this.popupTimerCountInSec == this.popupTime) {
          //Math.floor(time)
          this.popupAlertforViewCheck = true;
          this.$refs.player.pause();
        }
      } else {
        this.turnOffPopupViewCheckAlert = false;
      }

      //after restart from 0s
      this.restart = false;
    },
    onFullscreenChange(active) {
      this.isFullscreen = active;
      this.fullScreen = active;
    },
    closeDialog() {
      this.videoPlayerDialog = false;
    },
    async GetVideoData() {
      const request = {
        userID: parseInt(localStorage.getItem("UserID")),

        topicID: parseInt(localStorage.getItem("libraryId")),
      };
      const res = await this.$axios.post(
        `${this.web_url}GuidedLesson/GetVideoData`,
        request
      );
      if (res.data.data.length > 0) {
        this.items = res.data.data.map((v, i) => ({
          ...v,
          no: i + 1,
        }));

        this.loading = false;
        this.hasData = true;
      } else {
        this.loading = false;
        this.hasData = false;
      }
    },
    onCurrentSrcChange() {
      this.currentTime = 0;
      this.actualPlayTime = 0;
    },
  },
};
</script>

<style scoped>
.alert-row {
  height: 24px;
  display: flex;
  align-items: center;
  width: 100%;
}
.alert-message {
  display: none;
  color: red;
  font-weight: bold;
  text-align: right;
  width: 100%;
}
/* .scrollbar-container {
  padding-right: 5px;
  height: auto;
  padding-bottom: 1rem;
  padding-left: 3.6rem;
} */
/* .video-scrollbar {
  padding-bottom: 2.5rem;
  height: 25vh;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
} */

/* Hide the scrollbar track */
.video-scrollbar::-webkit-scrollbar {
  width: 10px; /* Safari and Chrome */
}

/* Customize the scrollbar track */
.video-scrollbar::-webkit-scrollbar-track {
  border-radius: 5px; /* Adjust radius as needed */
}

/* Customize the scrollbar thumb */
.video-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 15px; /* Adjust radius as needed */
}

.watermark-overlay {
  visibility: visible;
  position: absolute;
  top: 25px;
  right: 0px;
  padding-right: 25px;
  z-index: 999;
  color: #a4a4a4;
  font-size: 18px;
  opacity: 0.7;
}
.dialog-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}

.dialog-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 1024px) {
  ::v-deep .video-player-dialog {
    width: 850px !important;
    height: auto;
  }
  .video-scrollbar {
    padding-bottom: 2rem;
    height: 30vh;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    -ms-overflow-style: none;
  }
}
</style>

<style scoped>
.heart-icon-container {
  position: absolute;
  top: 20px;
  right: 24px;
  height: 30px;
  z-index: 1000 !important;
}
.watermark-overlays{
  position: absolute;
  top: 20px;
  right: 50px;
  height: 30px;
  z-index: 1000 !important;
  color: #a4a4a4;
  font-size: 18px;
  opacity: 0.7;
}
.back-icon-container {
  position: absolute;
  top: 20px;
  left: 24px;
  z-index: 1000 !important;
}
.controls-padding {
  padding-left: 18px !important;
  padding-right: 18px !important;
}
#ButtonAnimationControls {
  display: inline-block;
  text-align: center;
  color: transparent;
  border-radius: 4px !important;
  border: transparent !important;
  background: transparent;
  background: unset;
  padding-bottom: 12px;
  background-size: 100% 200%;
}
#ButtonAnimationHeart {
  height: 42px;
  display: inline-block;
  text-align: center;
  border-radius: 8px !important;
  background: #000000a3 !important;
  background-size: 100% 200%;
  padding: 8px 7px;
}
#ButtonAnimationBack {
  height: 42px;
  display: inline-block;
  text-align: center;
  border-radius: 8px !important;
  background: #000000a3 !important;
  background-size: 100% 200%;
  padding-bottom: 9px;
  padding-top: 7px;
  padding-left: 7px;
  padding-right: 7px;
}
::v-deep .pause-name.v-btn:not(.v-btn--round).v-size--default {
  min-width: 25px;
  min-height: 25px;
  padding-right: 11px;
  padding-left: 11px;
  box-shadow: unset !important;
}
::v-deep .play-name.v-btn:not(.v-btn--round).v-size--default {
  min-width: 23px;
  min-height: 25px;
  padding-right: 10px;
  padding-left: 11px;
  box-shadow: unset !important;
}
::v-deep .fullscreen-name.v-btn:not(.v-btn--round).v-size--default {
  min-width: 25px;
  min-height: 20px;
  padding-right: 5px;
  padding-left: 5px;
  box-shadow: unset !important;
}
</style>

<style scoped>
.progress-bar {
  width: 100%;
  height: 4px;
  background-color: #f5f5f5;
  cursor: pointer;
}

.progress {
  height: 100%;
  background-color: #a6cc39;
  width: 0%;
  transition: width 0.1s ease-in-out;
}

.progress-bar__background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.progress-bar__fill {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #a6cc39;
  transition: width 0.1s linear;
}
.progress-bar__handles {
  position: absolute;
  top: -5px;
  left: 0;

  background-color: #a6cc39;
  transform: translateX(-50%);
  transition: left 0.1s linear;
}
.progress-bar__handle {
  position: absolute;
  top: -5px;
  left: 0;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: #a6cc39;
  transform: translateX(-50%);
  transition: left 0.1s linear;
}
</style>

<style>
.controls {
  display: flex;
  width: 93%;
  height: 78px;
  position: absolute;
  flex-wrap: wrap;
  pointer-events: auto;
  box-sizing: border-box;
  background: #000000a3 !important;
  /**--vm-color-gray-500  --vm-color-white-700*/
  padding-top: 12px !important;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  border-radius: 8px;
  opacity: 1;
  visibility: visible !important;
  bottom: 10px !important;
}
.player {
  line-height: 1;
}
::v-deep .controls.active {
  opacity: 1;
  visibility: visible;
  justify-content: center !important;
}
</style>

<!-- comment section css -->
<style scoped>
.comment-paddingLeft4 {
  padding-left: 16px !important;
}
.marginRight-6 {
  margin-right: 24px !important;
}
.reply-textbox-paddingLeft {
  padding-left: 60px !important;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
  caret-color: #a6cc39 !important;
}
::v-deep .comment-textbox {
  color: #a6cc39 !important;
  caret-color: #a6cc39 !important;
}
.btn_hover_effect,
.reset-btn {
  color: #fff;
  width: 80%;
  font-size: 14px;
  z-index: 0;
  position: relative;
  display: inline-block;
}
.btn_hover_effect:before,
.reset-btn:before {
  background-color: #4fb14e;
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.5 !important;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.btn_hover_effect:hover:before,
.reset-btn:hover:before {
  background-color: #4fb14e;
  top: 0;
}
.btn_hover_effect:hover,
.reset-btn:hover {
  transition: 0.25s;
}
.btn_hover_effect:after,
.reset-btn:after {
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
}
::v-deep .v-dialog {
  overflow-x: hidden;
  overflow-y: hidden;
}
.scrollbar-container {
  padding-right: 5px;
  height: auto;
  padding: 0 0 1.5rem 0;
}
.video-scrollbar {
  padding: 0 2.4rem 2.2rem 1.7rem;
  height: 25vh;
  /* height: 50vh; */
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
}
@media screen and (max-width: 768px) {
  ::v-deep .video-player-dialog {
    width: 650px !important;
    height: auto;
  }
  .scrollbar-container {
    padding-right: 5px;
    height: auto;
    padding-bottom: 1rem;
    padding-left: 0px;
  }
  .video-scrollbar {
    padding: 0 0rem 2.2rem 0rem;
    height: 30vh;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
  }
}
@media only screen and (max-width: 600px) {
  ::v-deep .video-player-dialog .v-player {
    max-width: 100%;
  }
  .videoTitle-font {
    font-size: 12px !important;
  }
  .comment-paddingLeft4 {
    padding-left: 5px !important;
  }
  .reply-textbox-paddingLeft {
    padding-left: 0px !important;
  }
  .marginRight-6 {
    margin-right: 5px !important;
  }
  .watermark-overlay {
    visibility: hidden;
    position: absolute;
    top: 25px;
    right: 0px;
    padding-right: 25px;
    z-index: 999;
    color: #a4a4a4;
    font-size: 18px;
    opacity: 0.7;
  }
  .video-scrollbar {
    padding: 0;
    height: 25vh;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
  }
}
</style>
